<template>
  <RightSideBar @close="$emit('close','delete')" button-class="bg-dynamicBackBtn text-white" :submit="'Yes, Delete'" :cancel="'No, Cancel'" :loading="isLoading" @submit="submit">
    <template v-slot:title>
      <div class="text-darkPurple flex gap-5 items-center">
        <div class=""  @click="closeSidebar()"><icon icon-name="back_arrow" size="s" class="w-8 h-8 rounded text-romanSilver"/></div> Delete {{currentTab.toLowerCase().includes('folders') ? 'Folder' : 'File'}}
      </div>
    </template>
    <div class="flex flex-col justify-center items-center gap-2 mt-20">
        <img :src="require('@/assets/images/basket.svg')" alt="not_found">
        <div class="flex justify-center items-center text-center text-jet font-semibold">Confirm you want to delete this {{currentTab.toLowerCase().includes('folders') ? 'Folder' : 'File'}}?</div>
        <div class="flex justify-center items-center text-center px-10 text-sm">
          {{ !isRecycleBin ? 
              `${currentTab.toLowerCase().includes('folders') ? 'Folder' : 'File'} 
              are permanently deleted after 30 days. You can recover your 
              ${currentTab.toLowerCase().includes('folders') ? 'folder' : 'file'} from trash before then.`
            : 
              `Are you sure you want to delete this ${currentTab.toLowerCase().includes('folders') ? 'folder' : 'file'}? The ${currentTab.toLowerCase().includes('folders') ? 'folder' : 'file'} in the Recycle Bin will be automatically deleted after 30 days.`
          }}
        </div>
    </div>
  </RightSideBar>
</template>

<script>
import RightSideBar from "../RightSideBar";

export default {
  name: "Delete",
  components: {
    RightSideBar
  },
  props: {
    isRecycleBin: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    currentTab: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isLoading: false,
      isOpen: true,
    };
  },
  methods: {
    closeSidebar() {
      this.$emit('close','delete');
    },
   
    async submit() {
      if(!this.isRecycleBin){
          await this.deleteFileFolder()
      }else{
          await this.permanentDeleteFileFolder()
      }
    },
    async deleteFileFolder(){
      this.isLoading = true;
      const payload = {
          deletedByUserId: this.$AuthUser.id,
          finalDelete: false
        }
      try {
          if (this.currentTab.toLowerCase().includes('folders')) {
              await this.$_deleteFolderForOrg(this.id, payload);
          } else {
              await this.$_deleteFileForOrg(this.id, payload);
          }
          this.$emit('refresh');
          this.closeSidebar();
          this.isLoading = false;
          return this.$toasted.success(`${this.currentTab.slice(0, -3)} deleted successfully.`,{duration: 6000});
      } catch (error) {
        this.isLoading = false;
        return this.$toasted.error(`Error deleting a ${this.currentTab.toLowerCase().slice(0, -3)}`, {duration: 6000});
      }
    },
    async permanentDeleteFileFolder(){
      this.isLoading = true;
      const payload = {
          deletedByUserId: this.$AuthUser.id,
          finalDelete: true
        }
      try {
        if (this.currentTab.toLowerCase().includes('folders')) {
            await this.$_deleteFolderForOrg(this.id, payload);
        } else {
            await this.$_deleteFileForOrg(this.id, payload);
        }
        this.$emit('refresh');
        this.closeSidebar();
        this.isLoading = false;
        return this.$toasted.success(`${this.currentTab.slice(0, -3)} permanently deleted `,{duration: 6000});
      } catch (error) {
        this.isLoading = false;
        return this.$toasted.error(`Error permanently deleting a ${this.currentTab.toLowerCase().slice(0, -3)}.`, {duration: 6000});
      }
    },
  }
};
</script>

<style scoped>
</style>
