<template>
    <ValidationObserver>
        <RightSideBar @close="$emit('close', 'newFolder')" button-class="bg-dynamicBackBtn text-white" :submit="'Create'"
            :cancel="'Cancel'" :loading="isLoading" @submit="submit" :disabled-button="!group || !name">
            <template v-slot:title>
                <div class="text-darkPurple flex gap-5 items-center">
                    <div @click="closeSidebar">
                        <icon icon-name="back_arrow" size="s" class="w-8 h-8 rounded text-romanSilver" />
                    </div>
                    Create New Folder
                </div>
            </template>

            <div class="flex gap-2 w-full h-14 mt-2 mb-5 items-center">
                <div class="w-full flex p-3 rounded-lg gap-2 bg-guyabano">
                    <div class="flex flex-col justify-center">
                        <icon icon-name="home-storage" size="xs" class="size"/>
                    </div>
                    <div class="flex flex-col justify-center">
                        <p class="text-sm font-semibold text-jet">Organization Document</p>
                        <p class="text-xs font-normal text-jet"> Folder Count: {{ folders?.length }} </p>
                    </div>
                </div>
                <div class="h-8 border border-borderGrey"></div>
                <div class="w-full flex p-3 rounded-lg gap-2 bg-guyabano">
                    <div class="flex flex-col justify-center">
                        <CircularProgressBar :percentage="0" width="24px" height="24px" />
                    </div>
                    <div class="flex flex-col justify-center">
                        <p class="text-sm font-semibold text-jet">Current Utilization</p>
                        <p class="text-xs font-normal text-jet"> Using {{ kbToSize(totalFolderSize)}} of 5 GB </p>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-5" >
                <c-text label="Folder Name" placeholder="Enter" :rules="['required']" v-model="name" />

                <div class="w-full flex flex-col gap-1">
                    <GroupToolTip :isGroupToolTip="isGroupToolTip"
                        @on-enter="isGroupToolTip = true"
                        @on-leave="isGroupToolTip = false"
                    />
                    <v-select
                        v-model="group"
                        @input="handleGroupSelect"
                        :selectable="isSelect"
                        :options="filteredGroups"
                        label="name"
                        :reduce="option => option.name"
                        :clearable="false"
                        placeholder="-- Select --"
                        :rules="['required']"
                        class="style-select "
                    >
                        <template #option="{ name }">
                            <p v-if="name === '+ Add New Group'" class="add-option"
                                @click="isAddGroup = true"
                            >
                                {{ name }}
                            </p>
                            <p v-else class="text-jet text-sm">
                                {{ name }}
                            </p>
                        </template>
                        <template #selected-option="{ name }">
                            <span class="overflow-hidden pr14">{{ name }}</span>
                        </template>
                        <template #open-indicator="{ attributes }">
                            <span class="pr14" v-bind="attributes"><icon icon-name="caret-down" size="xsm" /></span>
                        </template>
                    </v-select>
                </div>

                <div class="w-full flex flex-col justify-start gap-1">
                    <p class="uppercase text-xs font-black text-romanSilver">Set max storage</p>
                    <div class="w-full h-10 text-base flex justify-start items-center gap-2 pl-2 relative text-darkPurple rounded-md border border-solid border-romanSilver">
                        <p class=" font-normal">{{ maxStorage }}</p>
                        <p class="font-semibold">GB</p>
                        <div class="h-full absolute right-3 flex flex-col">
                            <div @click="increaseGB" class="cursor-pointer">
                                <icon icon-name="caret-down" size="xsm"  class=" rotate"/>
                            </div>
                             <div @click="decreaseGB" class="cursor-pointer">
                                <icon icon-name="caret-down" size="xsm" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddGroup v-if="isAddGroup"
                :disableAddBtn="disableAddBtn"
                :groups="filteredGroups"
                @cancel="isAddGroup = false"
                @proceed-delete="proceedDeleteGroup"
                @proceed-edit="proceedEditGroup"
                @add-group="addGroup"
            />
            <DeleteGroup v-if="isDeleteGroup"
                :disableDelBtn="disableDelBtn"
                :groupName="groupName"
                @cancel="isDeleteGroup = false"
                @delete-group="deleteGroup"
            /> 
            <EditGroup v-if="isEditGroup"
                :groupName="groupName"
                :disableEditBtn="disableEditBtn"
                @cancel="isEditGroup = false"
                @edit-group="editGroup"
            />
        </RightSideBar>
    </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import "vue-select/dist/vue-select.css";
import RightSideBar from "../RightSideBar";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CText from "@scelloo/cloudenly-ui/src/components/text";

export default {
    name: "NewFolder",
    components: {
        ValidationObserver,
        RightSideBar,
        CSelect,
        CText,
        vSelect: () => import("vue-select"),
        AddGroup: () => import("./groupActionsModel/AddGroup"),
        DeleteGroup: () => import("./groupActionsModel/DeleteGroup"),
        EditGroup: () => import("./groupActionsModel/EditGroup"),
        GroupToolTip: () => import("./groupActionsModel/GroupToolTip"),
        CircularProgressBar: () => import("./DocsCircularProgressBar.vue"),
    },
    props: {
        currentTab: {
            type: String,
            default: ''
        },
        item: {
            type: Object,
            default: () => ({})
        },
        folders: {
            type: Array,
            default: () => []
        },
        groups: {
            type: Array,
            default: () => []
        },
        forOrg: Boolean,
        forEmployees: Boolean
    },
    data() {
        return {
            isGroupToolTip: false,
            isAddGroup: false,
            isDeleteGroup: false,
            isEditGroup: false,
            disableAddBtn: false,
            disableDelBtn: false,
            disableEditBtn: false,
            groupName: null,
            groupId: null,

            isLoading: false,
            group: null,
            name: '',
            maxStorage: 0,
            folder: [],
            addNewGroupOption: { name: '+ Add New Group', id: "234" },
        };
    },

    methods: {
        closeSidebar() {
            this.$emit('close', 'newFolder');
        },
        
        increaseGB(){
            if(this.maxStorage === 12){
                return this.maxStorage
            }
            return  this.maxStorage++;
        },
        decreaseGB(){
            if(this.maxStorage === 0){
                return this.maxStorage
            }
            return  this.maxStorage--
        },

        async submit() {
            const totalMaxStorage = this.folders.reduce((total, folder) => total + (folder.maxStorage || 0), 0);
            if (totalMaxStorage === 5) {
                    this.$toasted.error("Organisation document maximum storage exceeded", {
                    duration: "3000"
                });
                return;
            }
            const payload = {
                name: this.name,
                creatorUserId: this.$AuthUser.id,
                groupId: this.groupId,
                orgId: this.$orgId,
                userId: this.$AuthUser.id,
                forOrg: this.forOrg,
                forEmployees: this.forEmployees,
                maxStorage: this.maxStorage
            };
            
            this.isLoading = true;
            try {
                await this.$_createFolderForOrg(payload);
                this.$emit('refresh');
                this.closeSidebar();
                this.isLoading = false;

                return this.$toasted.success("Folder created successfully", {duration: 6000});
            } catch (error) {
                this.isLoading = false;
                return this.$toasted.error("Error creating a folder", {duration: 6000});
            }
        },

        handleGroupSelect(selectedGroup) {
            if (selectedGroup === '+ Add New Group') {
                 this.group = ''
                return;
            }
        },

        isSelect(option) {
            this.groupId = option.id
            return option
        },

        proceedDeleteGroup(group, id){
            this.groupName = group;
            this.isDeleteGroup = true; 
            this.isAddGroup = false;
            this.groupId = id
        },

        proceedEditGroup(group, id){
            this.groupName = group;
            this.isEditGroup = true; 
            this.isAddGroup = false;
            this.groupId = id
        },
        async addGroup(newGroup) {
            const groupNames = this.filteredGroups.map(item => item.name);
            if(newGroup && !groupNames.includes(newGroup)) {
                const orgId = this.$orgId;
                const payload = {name: newGroup, orgId};
                this.disableAddBtn = true;

                try {
                    await this.$_createAGroup(payload); 
                    this.disableAddBtn = false;
                    this.isAddGroup = false;
                    this.$emit('refresh');

                    return this.$toasted.success(`Group created successfully.`,{duration: 6000});
                } catch (error) {
                    this.disableAddBtn = false;
                    this.isAddGroup = false;
                    return this.$toasted.error(`Error adding new group!`,{duration: 6000})
                }
            } else {
                return this.$toasted.error(`Group already exists or is empty field.`,{duration: 6000})
            }
        },

        async deleteGroup() {
            const groupId = this.groupId;
            this.disableDelBtn = true;

            try {
                const {data} = await this.$_deleteAGroup(groupId); 
                this.disableDelBtn = false;
                this.isDeleteGroup = false;
                this.$emit('refresh');

                return this.$toasted.success(`${data.message}.`,{duration: 6000});
            } catch (err) {
                this.disableDelBtn = false;
                this.isDeleteGroup = false;
                return this.$toasted.error(`${err.message}`,{duration: 6000})
            }
        },

        async editGroup(group) {
            const groupNames = this.filteredGroups.map(item => item.name);
            if(group && !groupNames.includes(group)) {
                const orgId = this.$orgId;
                const groupId = this.groupId;
                const payload = {name: group, orgId};
                this.disableEditBtn = true;

                try {
                    await this.$_updateAGroup(groupId, payload); 
                    this.disableEditBtn = false;
                    this.isEditGroup = false;
                    this.$emit('refresh');

                    return this.$toasted.success(`Group edited successfully.`,{duration: 6000});
                } catch (error) {
                    this.disableEditBtn = false;
                    this.isEditGroup = false;
                    return this.$toasted.error(`Error editing a group!`,{duration: 6000})
                }
            } else {
                return this.$toasted.error(`Group already exists or is empty field.`,{duration: 6000})
            }
        },
        kbToSize(kb) {
            const sizes = ['KB', 'MB', 'GB', 'TB', 'PB'];
            if (kb === 0) return '0 KB';
            if (kb < 1) return `${(kb * 1024).toFixed(1)} KB`; // Converted small KB to Bytes
                const i = parseInt(Math.floor(Math.log(kb) / Math.log(1024)), 10);
                const sizeIndex = Math.min(i, sizes.length - 1);
            if (sizeIndex === 0) return `${kb.toFixed(1)} ${sizes[sizeIndex]}`;
            return `${(kb / (1024 ** sizeIndex)).toFixed(1)} ${sizes[sizeIndex]}`;
        }
    },
    computed: {
        totalFolderSize() {
            return this.folders.reduce((total, folder) => total + (folder.folderSize || 0), 0);
        },
        filteredGroups() {
            const groups =  this.groups.filter(group => group.id !== (this.item.group?.id || null));
            return [this.addNewGroupOption, ...groups];
        }
    },
};
</script>

<style scoped>
.payInfo_fakeInput {
    background-color: #EEEEEE;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border: 1px solid #878E99;
    border-radius: 5px;
}

.top{
    top: 2px !important;
}

.add-option{
    width: 100% !important;
    height: 100% !important;
    box-sizing: border-box;
    color: #2176FF;
    font-weight: 800;
    font-size: 12px;
    cursor: pointer;
    background-color: #FFFFFF;
}

.add-option:hover{
  background-color: #FFFFFF !important; /* Remove hover effect */
}

.pr14{
    padding-right: 8px !important;
}

.size{
    width: 24px !important;
    height: 24px !important;
}

.rotate{
    transform: rotate(-180deg) !important;
}
</style>