<template>
  <ValidationObserver rules="required">
    <RightSideBar @close="$emit('close','permissions')" button-class="bg-dynamicBackBtn text-white" :submit="'Share'" :cancel="'Go back'" :loading="isLoading" @submit="submit" :disabled-button="users.length < 1">
      <template v-slot:title>
        <div class="text-darkPurple flex gap-5 items-center">
          <div class=""  @click="closeSidebar()"><icon icon-name="back_arrow" size="s" class="w-8 h-8 cursor-pointer rounded text-romanSilver"/></div> Folder Permissions
        </div>
      </template>
      <div class="w-full" v-if="!showPrivilegeError">
        <div class="w-full relative">
          <v-select
            :clearable="false"
            placeholder="Search"
            label="fname"
            class="style-select"
            style="height: 40px; background: #F7F7FF !important; border: none !important; outline: none !important"
            :reduce="option => option"
            v-model="search"
            multiple
            @search="setAllOrgUsers"
            @input="handleSelect"
            :options="allOrgUsers"
            :filterable="false"
          >
            <template #search="{ attributes, events }">
              <div class="input-with-icon-container">
                <img
                  src="@/assets/images/magnifying-glass.png"
                  style="width: 20px; height: 20px; margin-left: 10px; margin-right: 5px;"
                />
                <input
                  v-bind="attributes"
                  v-on="events"
                  :placeholder="attributes.placeholder" />
              </div>
            </template>
            <template
              #option="{fname, lname, orgFunction, userDesignation, photo }"
            >
              <div class="flex">
                <span class="mt-2 ">
                  <img
                    class=""
                    :src="photo"
                    v-if="photo"
                    alt="profile pic"
                    style="height: 25px; width: 25px; border-radius: 5px;"
                  />
                  <div
                    style="height: 25px; width: 25px; border-radius: 5px;"
                    class="text-blueCrayola text-xs border text-center font-semibold pt-1"
                    v-else
                  >
                    {{ $getInitials(`${fname} ${lname}`) }}
                  </div>
                </span>
                <div class="ml-2 hover:text-white">
                  <div class="font-bold mt-1 text-sm cursor-pointer">
                    {{ fname }} {{ lname }}
                  </div>
                  <div class=" " style="font-size: 10px">
                    <span class="uppercase">{{
                      orgFunction ? orgFunction.name : ""
                    }}</span>
                    <span class="ml-2">{{
                      userDesignation ? `${userDesignation.name}` : ""
                    }}</span>
                  </div>
                </div>
              </div>
            </template>
            <template #selected-option="{fname, lname}">
              {{ fname }} {{ lname }}
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="bg-ghostWhite h-full m-auto flex justify-center items-center">
                <icon icon-name="caret-down" size="xsm" />
              </span>
            </template>
            <template #list-footer>
              <li style="text-align: center; font-size: 12px">
                Type name of employee to search more
              </li>
            </template>
          </v-select>
          <!-- <div @click="viewFilterTable" class="absolute top-0 right-0 bg-ghostWhite rounded-md w-14 h-9 mt-1 mr-1 z-10 flex justify-end items-center">
            <Icon class-name="cursor-pointer mr-5" size="xs" icon-name="sort_size"/>
          </div> -->
        </div>

        <div class="w-full flex flex-wrap">
          <div
            class="flex justify-between items-center gap-2 px-2 h-8 mt-3 mr-2"
            v-for="(user, index) in users"
            :key="index"
            style="background: #F7F7FF;border-radius: 5px;"
          >
            <p>{{ user.name }}</p>
            <div @click="removeUser(user.userId,index)"
              class="cursor-pointer"
            >
              <icon icon-name="close" />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="w-full mt-4">
        <p class="flex font-bold text-desire text-sm justify-center">
          You don't have employees privilege
        </p>
      </div>
      <div class="flex flex-col gap-1 mt-5">
        <div class="w-full flex justify-start items-center">
          <p>Access</p>
          <!-- <div class="text-blueCrayola font-bold text-xs flex items-center gap-2">
            <input type="checkbox"> Anyone with link can access
          </div> -->
        </div>
        <c-select
          placeholder="--Select--"
          :options="['Viewer', 'Commentor', 'Editor']"
          label=""
          variant="w-full"
          :rules="['required']"
          v-model="access"
        />
      </div>
      <div class="flex flex-col gap-2 mt-5">
        <div class="flex gap-2 text-sm text-jet">
          <input type="checkbox" v-model="editorCanAddUser">
          Editors can add new Users
        </div>
        <div class="flex gap-2 text-sm text-jet">
          <input type="checkbox" v-model="disableDownload">
          Disable Download
        </div>
        <div class="flex gap-2 text-sm text-jet">
          <input type="checkbox" v-model="notifyPeople">
          Notify people
        </div>
        <textarea cols="10" rows="6" placeholder="--Enter Here--" class="border border-romanSilver resize-none" v-model="message"></textarea>
      </div>

      <div class="line"/>

      <div class="w-full flex justify-between items-center">
        <p>Access & Permissions:</p>
        <div v-if="currentTab.toLowerCase().includes('folders')">
          <div class="flex justify-between items-center gap-6" v-if="item.folderPermissions.length > 0">
            <div class="flex items-center gap-1">
              <div v-for="(user, index) in getUserPhotos(item.folderPermissions)" :key="index">
                <img :src="user.photo" alt="" v-if="user.photo" class="stacked-image w-8 h-8 rounded-full">
                <div v-else class="w-8 h-8 rounded-full flex justify-center items-center text-sm text-blueCrayola border text-center font-semibold">
                  {{ $getInitials(user.name) }} 
                </div>
              </div>
              <span v-if="item.folderPermissions[0].permissionUsers && item.folderPermissions[0].permissionUsers.length >= 3" class="text-blueCrayola font-semibold">
                +{{item.folderPermissions[0].permissionUsers.length - 2}}
              </span>
            </div>
            <p @click="viewAccessList()"
              v-if="item.folderPermissions[0].permissionUsers && item.folderPermissions[0].permissionUsers.length >= 3" 
              class="text-xs text-blueCrayola cursor-pointer uppercase font-semibold"
            >
              View All
            </p>
          </div>
          <p v-else class="font-bold text-desire text-xs">None</p>
        </div>

        <div v-if="currentTab.toLowerCase().includes('files')">
          <div class="flex justify-between items-center gap-6" v-if="item.filePermissions.length > 0">
            <div class="flex items-center gap-1">
              <div v-for="(user, index) in getUserPhotos(item.filePermissions)" :key="index">
                <img :src="user.photo" alt="" v-if="user.photo" class="stacked-image w-8 h-8 rounded-full">
                <div v-else class="w-8 h-8 rounded-full flex justify-center items-center text-sm text-blueCrayola border text-center font-semibold">
                  {{ $getInitials(user.name) }} 
                </div>
              </div>
              <span v-if="item.filePermissions[0].permissionUsers && item.filePermissions[0].permissionUsers.length >= 3" class="text-blueCrayola font-semibold">
                +{{item.filePermissions[0].permissionUsers.length - 2}}
              </span>
            </div>
            <p @click="viewAccessList()"
              v-if="item.filePermissions[0].permissionUsers && item.filePermissions[0].permissionUsers.length >= 3" 
              class="text-xs cursor-pointer text-blueCrayola uppercase font-semibold"
            >
              View All
            </p>
          </div>
          <p v-else class="font-bold text-desire text-xs">None</p>
        </div>
      </div>
<!-- 
      <FilterTable
        v-if="filterTable"
        :filter-data="filterArray"
        searchPlaceHolder="Search by Name"
        @close="closeFilter($event)"
        @getParams="searchFilter($event)"
      /> -->

      <AccessList
        v-if="openAccessList"
        @close="openAccessList = false"
        :current-tab="currentTab"
        :item="item"
        :people="people"
        @refresh="refresh($event)"
      />
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import "vue-select/dist/vue-select.css";
import { mapState } from "vuex";
import RightSideBar from "../RightSideBar";
import CardFooter from "../CardFooter";
import Card from "../Card";
import CSelect from "@scelloo/cloudenly-ui/src/components/select"
// import FilterTable from "../FilterTable";

export default {
  name: "PermissionsSetting",
  components: {
    ValidationObserver,
    RightSideBar,
    CSelect,
    Card,
    CardFooter,
    // FilterTable,
    vSelect: () => import("vue-select"),
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    currentTab: {
      type: String,
      default: ''
    },
    files: {
      type: Array,
      default: () => ([])
    },
    folders: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      isLoading: false,
      isOpen: true,
      group: '',
      // filterTable: false,
      userIds: [],
      users: [],
      // filterArray: [],
      access: '',
      editorCanAddUser: false,
      disableDownload: false,
      notifyPeople: false,
      message: '',
      people: [],
      search: "",
      allOrgUsers: [],
      showPrivilegeError: false,
      permissions: [],
      openAccessList: false
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.employee.loading,
      employees: (state) => state.employee.employees
    })
  },
  watch: {
    employees(newVal){
        const people = newVal.map((employee) => ({
          id: employee.id,
          userId: employee.userId,
          name: `${employee.fname} ${employee.lname}`
        }));
        this.people = people;
        // this.filterArray = [{ header: "People", value: "people", optionsBox: people }];
    }
  },
  methods: {
    closeSidebar() {
      this.$emit('close', 'permissions');
    },
    viewAccessList() {
      this.openAccessList = true
    },
    async submit() {
      try {
        this.isLoading = true;
        const payload = {
          userIds: this.userIds,
          access: this.access.toLocaleLowerCase(),
          editorCanAddUser: this.editorCanAddUser,
          disableDownload: this.disableDownload,
          notifyPeople: this.notifyPeople,
          message: this.message
        }
        await this.$_managePermissions( this.currentTab.toLowerCase().includes('folders') ? this.item.folderId : this.item.fileId, payload, this.currentTab.toLowerCase().includes('folders') ? 'folders' : 'files');
        this.$emit('refresh');
        this.closeSidebar();
        return this.$toasted.success("Permissions successfully set", {duration: 6000});
      } catch (error) {
        console.log(error, "error")
        return this.$toasted.error("Erorr setting permissions", {duration: 6000});
      } finally {
        this.isLoading = false;
      }
    },
    // viewFilterTable() {
    //     this.filterTable = true;
    // },
    // closeFilter(val) {
    //   if (val) {
    //     this.filterTable = false;
    //   }
    // },
    // searchFilter(params) {
    //   const start = params.indexOf("&people=") + "&people=".length;
    //   const result = params.substring(start);
    //   let searched = [];
    //   if(result){
    //       searched = this.people.filter(employee => employee.name === result.substring(1) || employee.id === result)
    //   }
    //   if(searched.length > 0){
    //     this.userIds.push(searched[0].id);
    //     this.users.push({id: searched[0].id, name: searched[0].name});
    //   }
    //   this.closeFilter(true);
    // },
    removeUser(id, index){
      this.users.splice(index, 1);
      this.userIds = this.userIds.filter(userId => userId !== id)
    },
    async getEmployeeDetails() {
      try {
        await this.$handlePrivilege(
          "employeeDirectory",
          "viewAllEmployeesRegister"
        );
        this.$store.dispatch(
          "employee/getEmployeesWithQueries",
          "&page=1&perPage=50"
        );
        this.showPrivilegeError = false;
      } catch (error) {
        this.showPrivilegeError = true;
      }
    },
    async setAllOrgUsers(search, loading) {
      loading(true);
      try {
        const attributes = "id,fname,lname,photo,userId";
        const { data } = await this.$_getAllEmployeesWithQueries(
          `search=${search}&perPage=1000&payrollStatus=active&attributes=${attributes}`
        );
        this.allOrgUsers = data.data.employees;
        loading(false);
      } catch (err) {
        throw new Error(err);
      }
    },
    handleSelect(selected) {
      this.userIds.push(selected[0].userId);
      this.users.push({id: selected[0].id, userId: selected[0].userId, name: `${selected[0].fname} ${selected[0].lname}`});
      this.search = "";
    },
    getUserPhotos(data) {
      const userPhotos = [];
      if(data[0].permissionUsers.length >= 3){
        data[0].permissionUsers.slice(0,2).forEach(permissionUser => {
          userPhotos.push({
            name:`${permissionUser.accessUser?.fname  } ${  permissionUser.accessUser?.lname}`,
            photo:permissionUser.accessUser.photo ? permissionUser.accessUser.photo : null,
          });
        });
      }else{
        data[0].permissionUsers.forEach(permissionUser => {
          userPhotos.push({
            name:`${permissionUser.accessUser?.fname  } ${  permissionUser.accessUser?.lname}`,
            photo:permissionUser.accessUser.photo ? permissionUser.accessUser.photo : null,
          });
        });
      }
      return userPhotos;
    },
  },
  async mounted() {
    await this.getEmployeeDetails() 
  },
};
</script>

<style scoped>
.payInfo_fakeInput {
  background-color: #EEEEEE;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #878E99;
  border-radius: 5px;
}
.gap-1 {
  gap: 0px !important;
}
textarea {
  display: flex;
  align-items: center;
  height: 120px;
  padding: 20px 10px;
  border-radius: 5px;
  outline: none;
  margin-top: 10px;
}

.input-with-icon-container {
  width: 100%;
  height: 40px;
  margin: auto;
  display: flex;
  justify-items: start;
  align-items: center;
  background-color: #F7F7FF; 
  border-radius: 6px;
  border: none !important;
  outline: none !important;
}

.input-with-icon-container input{
  width: 100%;
  height: 100%;
  background-color: #F7F7FF !important; 
  border: none !important;
  outline: none !important;
}

.line{
  border: 1px solid #00000029;
  width: 100%;
  height: 0;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
}
</style>
