<template>
    <ValidationObserver rules="required">
      <RightSideBar @close="$emit('close','info')" button-class="bg-dynamicBackBtn text-white" :submit="'Manage Permissions'" submit-button-width="12rem" :cancel="'Cancel'" :loading="isLoading" @submit="openPermisssions">
        <template v-slot:title>
          <div class="text-darkPurple flex gap-3 font-bold items-center">
            <div class=""  @click="closeSidebar()"><icon icon-name="back_arrow" size="s" class="w-8 h-8 rounded text-romanSilver"/></div> Information
          </div>
        </template>

        <div v-if="currentTab.toLowerCase().includes('folders')" class="font-semibold text-base text-darkPurple">
          File/Folder Details
        </div>
        <div v-else class="font-semibold text-base text-darkPurple">
          File Details
        </div>

        <div class="flex flex-col justify-center items-center gap-3 mt-3 w-full">

            <img :src="require('@/assets/images/info.svg')" alt="not_found">

            <div class="bg-ghostWhite p-5 info w-full flex flex-col gap-5 text-sm" v-if="currentTab.toLowerCase().includes('folders')">
                <div class="w-full flex justify-between items-between">
                    <div class="">Type:</div>
                    <div class="font-bold">{{item.folderType ? `.${item.folderType}` : "None" }}</div>
                </div>
                <div class="w-full flex justify-between items-between">
                    <div class="">Size:</div>
                    <div class="font-bold">{{ kbToSize(item.folderSize) }}</div>
                </div>
                <div class="w-full flex justify-between items-between">
                    <div class="">Location:</div>
                    <div class="font-bold">My Document/{{item.name}}/Vendors</div>
                </div>
                <div class="w-full flex justify-between items-between">
                    <div class="">Owner:</div>
                    <div class="font-bold">{{item.createdBy.fname}} {{item.createdBy.lname}}</div>
                </div>
                <div class="w-full flex justify-between items-between">
                    <div class="">Created:</div>
                    <div class="font-bold">{{ $DATEFORMAT( new Date(item.createdAt), "MMMM dd, yyyy") }}</div>
                </div>
                <div class="w-full flex justify-between items-between">
                    <div class="">Last Modified:</div>
                    <div class="font-bold">{{ $DATEFORMAT( new Date(item.updatedAt), "MMMM dd, yyyy") }}</div>
                </div>
                <div class="w-full flex justify-between items-between">
                  <div class="">Access & Permissions</div>
                  <div class="" v-if="item.filePermissions && item.filePermissions.length > 0">
                    <div class="" v-for="(user, index) in getUserPhotos(item.filePermissions)" :key="index">
                        <img :src="user.photo" alt="" v-if="user.photo" class="w-8 h-8">
                        <div v-else class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-2">
                        {{ $getInitials(user.name) }}
                        </div>
                    </div>
                  </div>
                  <p v-else class="font-bold">Private</p>
                </div>
            </div>

            <div class="bg-ghostWhite p-5 info w-full flex flex-col gap-5 text-sm" v-else>
                <div class="w-full flex justify-between items-between">
                    <div class="">Type:</div>
                    <div class="font-bold">.{{item.fileType}}</div>
                </div>
                <div class="w-full flex justify-between items-between">
                    <div class="">Size:</div>
                    <div class="font-bold">{{ kbToSize(item.fileSize) }}</div>
                </div>
                <div class="w-full flex justify-between items-between">
                    <div class="">Location:</div>
                    <div class="font-bold">My Document/{{item.name}}/Vendors</div>
                </div>
                <div class="w-full flex justify-between items-between">
                    <div class="">Owner:</div>
                    <div class="font-bold">{{item.createdBy.fname}} {{item.createdBy.lname}}</div>
                </div>
                <div class="w-full flex justify-between items-between">
                    <div class="">Created:</div>
                    <div class="font-bold">{{ $DATEFORMAT( new Date(item.createdAt), "MMMM dd, yyyy") }}</div>
                </div>
                <div class="w-full flex justify-between items-between">
                    <div class="">Last Modified:</div>
                    <div class="font-bold">{{ $DATEFORMAT( new Date(item.updatedAt), "MMMM dd, yyyy") }}</div>
                </div>
                <div class="w-full flex justify-between items-between">
                  <div class="">Access & Permissions</div>
                  <div class="" v-if="item.filePermissions.length > 0">
                    <div class="" v-for="(user, index) in getUserPhotos(item.filePermissions)" :key="index">
                        <img :src="user.photo" alt="" v-if="user.photo" class="w-8 h-8">
                        <div v-else class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-2">
                        {{ $getInitials(user.name) }}
                        </div>
                    </div>
                  </div>
                  <p v-else class="font-bold">Private</p>
                </div>
            </div>
        </div>
      </RightSideBar>
    </ValidationObserver>
  </template>

  <script>
  import { ValidationObserver } from "vee-validate";
  import RightSideBar from "../RightSideBar";

  export default {
    name: "Information",
    components: {
      ValidationObserver,
      RightSideBar
    },
    props: {
        item: {
        type: Object,
        default: () => ({})
      },
      currentTab: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        isLoading: false,
        isOpen: true,
      };
    },
    methods: {
        closeSidebar() {
            this.$emit('close','info');
        },
        openPermisssions() {
          this.$emit("open-permisssions");
        },
        getUserPhotos(data) {
            const userPhotos = [];
            data.forEach(item => {
                item.permissionUsers.forEach(permissionUser => {
                    userPhotos.push({
                    name:`${permissionUser.accessUser?.fname  } ${  permissionUser.accessUser?.lname}`,
                    photo:permissionUser.accessUser.photo ? permissionUser.accessUser.photo : null,
                    });
                });
            });
            return userPhotos;
        },
        kbToSize(kb) {   
          const sizes = ['KB', 'MB', 'GB', 'TB', 'PB'];
          if (kb === 0) return '0 KB';
          if (kb < 1) return `${(kb * 1024).toFixed(1)} KB`; // Converted small KB to Bytes
              const i = parseInt(Math.floor(Math.log(kb) / Math.log(1024)), 10);
              const sizeIndex = Math.min(i, sizes.length - 1);
          if (sizeIndex === 0) return `${kb.toFixed(1)} ${sizes[sizeIndex]}`;
          return `${(kb / (1024 ** sizeIndex)).toFixed(1)} ${sizes[sizeIndex]}`;
        }
    }
  };
  </script>

  <style scoped>
    .info{
        border-radius: 12px;
    }
  </style>
