<template>
    <ValidationObserver rules="required">
      <RightSideBar @close="$emit('close','move-folder-to-folder')" button-class="bg-dynamicBackBtn text-white" :submit="'Save'" :cancel="'Cancel'" :loading="isLoading" @submit="submit" :disabled-button="!folder">
        <template v-slot:title>
          <div class="text-darkPurple flex gap-5 items-center">
            <div class=""  @click="closeSidebar()"><icon icon-name="back_arrow" size="s" class="w-8 h-8 rounded text-romanSilver"/></div> Move Folder To Folder
          </div>
        </template>
        <div class="flex flex-col gap-5">
            <div class="font-bold">Move “{{item.name}}”</div>
            <div class="flex flex-col justify-center border-b pb-5">
                <span class="text-sm mb-2 text-blueCrayola font-black">Current location:</span>
                <span class="text-sm mb-2 text-jet font-bold">Move From</span>
                <div class="payInfo payInfo_fakeInput text-romanSilver bg-cultured text-center flex gap-2 text-base">
                    <span>{{ item.name}}</span>
                </div>
            </div>
            <c-select
            placeholder="--Select--"
            :options="filteredFolders || []"
            label="Move To"
            variant="w-full"
            :rules="['required']"
            v-model="folder"
            />
        </div>
      </RightSideBar>
    </ValidationObserver>
  </template>

  <script>
  import { ValidationObserver } from "vee-validate";
  import RightSideBar from "../RightSideBar";

  import CSelect from "@scelloo/cloudenly-ui/src/components/select"

  export default {
    name: "MoveFolderToFolder",
    components: {
      ValidationObserver,
      RightSideBar,
      CSelect
    },
    props: {
        item: {
        type: Object,
        default: () => ({})
      },
      currentTab: {
        type: String,
        default: ''
      },
      folders: {
        type: Array,
        default: () => ([])
      },
    },
    data() {
      return {
        isLoading: false,
        isOpen: true,
        folder:'',
        folderId: ''
      };
    },
    methods: {
        closeSidebar() {
        this.$emit('close','move-folder-to-folder');
        },
        async submit() {
            this.isLoading = true;
            const payload = {
                folderId: this.folderId,
                parentld: this.item.folderId
            }
            try {
              await this.$_moveOrgFolderToFolder(payload);
              this.$emit('refresh');
              this.closeSidebar();
              this.isLoading = false;

              return this.$toasted.success("Folder moved successfully", {duration: 6000});
            } catch (error) {
                this.isLoading = false;
                return this.$toasted.error("Error moving folder", {duration: 6000});
            }
        }
    },
    watch:{
      folder(newVal){
        const folder = this.folders.filter(folder => folder.name === newVal);
        this.folderId = folder[0].folderId
      }
    },
    computed: {
        filteredFolders() {
            const folders = this.folders.filter(folder => folder.folderId !== this.item.folderId);
            return folders
        },
    }
  };
  </script>

  <style scoped>
      .payInfo_fakeInput{
      background-color: #EEEEEE;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 10px 15px;
      border: 1px solid #878E99;
      border-radius: 5px;
    }
    .border-b{
        border-bottom: 1px dashed #878E99;
    }
  </style>
