<template>
  <div>
  <div class="w-full h-full flex flex-col mt-7">
    <div v-if="!deleted" class="w-full flex py-6 px-12 gap-6" style="background-color: #f6f6f6">
      <div class="flex flex-grow">
        <div class="flex flex-col w-3/4 justify-center gap-2">
          <p class="text-xl font-bold">
            Securely store and share your organisation files.
          </p>
          <p class="text-sm">
            Upload and manage files that help you run your organisation here.
          </p>
        </div>
        <div class="flex w-1/4 justify-end">
          <div class="flex flex-col justify-center">
            <img
              src="@/assets/images/folder_open.png"
              style="width: 100px; height: 100px"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-col gap-4 mt-10" v-if="currentTab.toLowerCase().includes('folders')">
      <p v-if="allFolders.length > 0" class="text-xs font-black text-romanSilver uppercase">
        Recently {{ !deleted ? `Added` : `Deleted`}} ({{ allFolders.length}})
      </p>
      <div class="w-full flex justify-between items-center gap-2">
        <div class="cursor-pointer"
          @click="prevItems" 
          v-if="allFolders.length > itemsPerPage && currentPage > 0"
        >
          <icon icon-name="chevronLeft" size="xsm" />
        </div>
        <div class="w-full gap-2 card-container">
          <Card v-for="(item, index) in visibleItems" :key="index" class="card">
            <div class="flex p-4 gap-3 cursor-pointer" :class="currentFolderTab == item.folderId && ' bg-cultured'" @click="handleItemClick({id: item.folderId, type: 'folder'})">
              <div class="flex flex-col justify-center">
                <icon icon-name="document" size="xsm" />
              </div>
              <div class="flex flex-col justify-center">
                <p class="text-sm font-semibold break-all">{{ item.name }}</p>
                <p class="text-xs font-black text-romanSilver">
                  {{ item.totalFolders ? item.totalFolders + ' Folders' :  item.numberOfFiles + ' Files'}}, {{kbToSize(item.folderSize)}}.
                </p>
              </div>
            </div>
          </Card>
        </div>
        <div class="cursor-pointer"
          @click="nextItems" 
          v-if="allFolders.length > itemsPerPage && currentPage < maxItems()"
        >
          <icon icon-name="chevronRight" size="xsm" />
        </div>
      </div>
    </div>
    <div class="w-full flex flex-col gap-4 mt-10" v-if="currentTab.toLowerCase().includes('files')">
      <p v-if="files.length > 0" class="text-xs font-black text-romanSilver uppercase">
        Recently {{ !deleted ? `Added` : `Deleted`}} ({{ files.length}})
      </p>
      <div class="w-full flex justify-between items-center gap-2">
        <div class="cursor-pointer"
          @click="prevItems" 
          v-if="allFiles.length > itemsPerPage && currentPage > 0"
        >
          <icon icon-name="chevronLeft" size="xsm" />
        </div>
        <div class="w-full gap-2 card-container">
          <Card v-for="(item, index) in visibleItems" :key="index" class="card">
            <div class="flex p-4 gap-3 cursor-pointer" :class="currentFileTab === item.fileId && ' bg-cultured'" @click="handleItemClick({id: item.fileId, type: 'file'})">
              <div class="flex flex-col justify-center" v-if="item.fileType">
                <icon icon-name="doc" size="s" class="w-8 h-8 rounded" v-if="item.fileType.includes('doc')"/>
                <icon icon-name="xlsx" size="s" class="w-8 h-8 rounded" v-else-if="item.fileType.includes('xlsx')"/>
                <icon icon-name="csv" size="s" class="w-8 h-8 rounded" v-else-if="item.fileType.includes('csv')"/>
                <icon icon-name="png" size="s" class="w-8 h-8 rounded" v-else-if="item.fileType.includes('png')"/>
                <icon icon-name="pdf" size="s" class="w-8 h-8 rounded" v-else-if="item.fileType.includes('pdf')"/>
                <icon icon-name="ppt" size="s" class="w-8 h-8 rounded" v-else-if="item.fileType.includes('ppt')"/>
                <icon icon-name="jpg" size="s" class="w-8 h-8 rounded" v-else-if="item.fileType.includes('jpg')"/>
                <icon icon-name="jpg" size="s" class="w-8 h-8 rounded" v-else-if="item.fileType.includes('jpeg')"/>
              </div>
              <div class="flex flex-col justify-center">
                <p class="text-sm font-semibold break-all">{{ item.name }}</p>
                <p class="text-xs font-black text-romanSilver">
                  {{ kbToSize(item?.fileSize)}}.
                </p>
              </div>
            </div>
          </Card>
        </div>
        <div class="cursor-pointer"
          @click="nextItems" 
          v-if="allFiles.length > itemsPerPage && currentPage < maxItems()"
        >
          <icon icon-name="chevronRight" size="xsm" />
        </div>
      </div>   
    </div>
  </div>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  name: "Folders",
  components: {
    Card,
  },
  props: {
    folders: {
      type: Array,
      required: true
    },
    currentFolderTab: {
      type: String,
      required: true
    },
    files: {
      type: Array,
      required: true
    },
    currentFileTab: {
      type: String,
      required: true
    },
    currentTab: {
      type: String,
      required: true
    },
    deleted: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      items: [
      ],
      isLoading: true,
      allFolders:[],
      allFiles:[],
      currentPage: 0,
      itemsPerPage: 4,
    };
  },
  computed:{
    visibleItems(){
      if(this.currentTab.toLowerCase().includes('folders')){
        const start = this.currentPage * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.allFolders.slice(start, end);
      }
      if(this.currentTab.toLowerCase().includes('files')){
        const start = this.currentPage * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.allFiles.slice(start, end);
      }
    }
  },
  methods: {
    maxItems(){
      if(this.currentTab.toLowerCase().includes('folders')){
        return Math.ceil(this.allFolders.length / this.itemsPerPage) - 1;
      }
      if(this.currentTab.toLowerCase().includes('files')){

        return Math.ceil(this.allFiles.length / this.itemsPerPage) - 1;
      }
    },
    nextItems() {
      if (this.currentPage < this.maxItems()) {
        this.currentPage++;
      }
    },
    prevItems() {
      if (this.currentPage > 0) {
        this.currentPage--;
      }
    },
    handleItemClick(obj) {
      this.$emit("updateActiveTab", obj);
    },
    getTotalSize(array, type) {
      let totalSize = 0;

      array.forEach(folder => {
        totalSize += parseInt(folder[type], 10);
      });
      return totalSize ;
    },
    kbToSize(kb) {   
      const sizes = ['KB', 'MB', 'GB', 'TB', 'PB'];
      if (kb === 0) return '0 KB';
      if (kb < 1) return `${(kb * 1024).toFixed(1)} KB`; // Converted small KB to Bytes
          const i = parseInt(Math.floor(Math.log(kb) / Math.log(1024)), 10);
          const sizeIndex = Math.min(i, sizes.length - 1);
      if (sizeIndex === 0) return `${kb.toFixed(1)} ${sizes[sizeIndex]}`;
      return `${(kb / (1024 ** sizeIndex)).toFixed(1)} ${sizes[sizeIndex]}`;
    }
  },
  watch: {
    folders(newValue) {
      if(newValue.length > 0){
        this.allFolders= [{
        "folderId": "all",
        "name": "All folders",
        "numberOfFiles": "1",
        "totalFolders": newValue.length,
        "folderSize": this.getTotalSize(newValue, 'folderSize'),
        },...newValue];
      }
    },
    files(newValue) {
      if(newValue.length > 0){
        this.allFiles= [{
        "fileId": "all",
        "name": "All files",
        "fileSize": this.getTotalSize(newValue, 'fileSize'),
        },...newValue];
      }
    }
  },
};
</script>
<style scoped>
  .border{
    border: 0.4px solid #E99323;
    background: #E993231F;
  }

  .card-container{
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
  }

  .card{
      border: 1px solid #C2C7D6;
      border-radius: 12px !important;
  }
</style>
