<template>            
  <RightSideBar @close="$emit('close')" button-class="bg-dynamicBackBtn text-white" :submit="'Save'" :cancel="'Cancel'" :loading="isLoading" @submit="submit" :disabled-button="!isUploaded">
    <template v-slot:title>
      <div class="text-darkPurple flex gap-2 justify-center items-center">
          <div @click="closeSidebar()"><icon icon-name="back_arrow" size="s" class="w-8 h-8 rounded text-romanSilver"/></div> 
          <p class="font-bold">Upload New Folder(s)</p>    
      </div>
    </template>

    <div v-if="setPermission" @click="openPermisssions" class="text-blueCrayola cursor-pointer flex gap-2 justify-start items-center">
      <div>
        <icon icon-name="lock-icon" size="s" class="text-blueCrayola"/>
      </div> 
      <p class="font-semibold text-sm">Set File Permissions</p>    
    </div>

    <div class="flex flex-col mt-4">
      <p class="text-darkPurple text-sm">Upload to folder</p>
          
      <c-select
          placeholder="--Select Folder--"
          :options="options"
          variant="w-full"
          v-model="selected"
      />
    </div>

    <div class="flex flex-col mt-4">
      <p class="text-darkPurple text-sm">Add Tags</p>
      
      <input 
        v-model="tags"
        type="text" placeholder="-- Enter tags seperated by commas --"
        class="w-full h-10 mt-1 px-3 rounded-md outline-none border border-solid border-romanSilver"
      /> 
    </div>

    <div
      ref="dropContainer"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="handleDropFiles"
      class="w-full mt-4 border border-dashed border-romanSilver bg-white flex flex-col justify-center items-center gap-4 py-4 rounded-md"
    >
      <icon icon-name="storage" size="s" class="storage"/>
      <p ref="dropFirstChild"
        class="w-2/3 mx-auto text-center flex flex-col justify-center items-center text-darkPurple"
      >
          <span ref="dropFirstInner">Drag and drop folder here</span>
          <span>OR</span>
      </p>
      <Button ref="btnDrop"
        @click="triggerInputFile" 
        class="text-white rounded-md bg-dynamicBackBtn flex flex-col justify-center items-center w-28 h-9"
      >
          Browse Folder
      </Button>
      <input type="file" webkitdirectory @change="handleInputFiles" ref="fileInput" multiple hidden>
      <p class="text-romanSilver text-xs">*supported file type: pdf, doc, xlsx, csv, ppt and png or jpg.</p>
    </div>

    <div v-if="uploading"  class="w-full h-auto flex flex-col justify-center items-center gap-3 mt-4">
      <div class="w-full border border-dashed border-romanSilver" />
      <p class="w-full  my-4 text-left uppercase font-semibold text-romanSilver text-xs">Uploading</p>
      <p class="w-full text-center uppercase font-black text-jet text-xs">
        No Uploads in Progress
      </p>
      <div class="line" />

      <div class="w-full h-9 flex justify-between items-center gap-6">
        <div class="w-auto h-full flex justify-start items-center gap-3">
          <div class="w-9 h-full">
            <icon icon-name="icon-folder" size="xs" class="icon"/>
          </div>

          <div class="flex flex-col justify-center items-start">
            <p class="text-blackJet text-base font-semibold py-0 my-0">{{ fileName }} {{ fileType }}</p>
            <p v-if="networkError" class="text-xs py-0 my-0">
                <span class="text-desire">
                    Upload Interrupted
                </span>
                <span class="text-romanSilver "> 
                    • ---
                </span>
            </p>
          </div>
        </div>

        <div class="flex gap-1">
          <div v-if="networkError" @click="retryUpload" class="cursor-pointer h-full w-9">
              <icon icon-name="icon-undo" size="s" class="icon"/>
          </div>
          <div @click="cancelUpload" class="cursor-pointer h-full w-9">   
              <icon icon-name="icon-close" size="s" class="icon"/>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isUploaded" class="w-full h-auto flex flex-col gap-4 my-4">
      <div class="w-full border border-dashed border-romanSilver" />
      <p class="w-full text-left uppercase font-semibold text-romanSilver text-xs">Uploaded Files</p>

      <div v-if="files.length > 0" class="w-full h-auto flex flex-col gap-4">
        <div v-for="(file, index) in files" :key="index" 
          class="w-full h-9 flex justify-between items-center gap-6"
        >
          <div class="w-auto h-full flex justify-start items-center gap-3">
            <div class="w-9 h-full">
              <icon icon-name="icon-folder" size="xs" class="icon"/>
            </div>

            <div class="flex flex-col justify-center items-start">
              <p class="text-blackJet text-base font-semibold py-0 my-0">{{ file.fileName }} {{ file.mimeType }}</p>
              <p class="text-xs py-0 my-0">
                  <span class="text-blueCrayola">
                      {{ folderName }}
                  </span>
                  <span class="text-romanSilver "> 
                      • {{ file.fileSize.toFixed(2) }}
                  </span>
              </p>
            </div>
          </div>
          <div @click="deleteUploadedFile(index)" class="cursor-pointer h-full w-9">
            <icon icon-name="trash-icon" size="s" class="icon"/>
          </div>    
        </div>
      </div>
    </div>
  </RightSideBar>
</template>

<script>
import axios from 'axios';
import RightSideBar from "../RightSideBar";
import Card from "../Card";
import CSelect from "@scelloo/cloudenly-ui/src/components/select"

export default {
  name: "UploadFile",
  components: {
    RightSideBar,
    CSelect,
    Card
  },
  props: {
    folders: {
      type: Array,
      default: () => ([])
    },
    setPermission: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      selected: "",
      tags: "",
      isLoading: false,
      optionsBox: [],
      folderName: "",
      isUploaded: false,
      uploading: false,
      networkError: false,
      fileName: '',
      fileType: '',
      uploadFile: null,
      imageSizeMB: 0,
      allFiles: []
    };
  },
  computed: {
    options() {
        return this.optionsBox
    },
    selectedOption() {
        return this.selected
    },
    files() {
      return this.allFiles
    }
  },
  watch:{
    selected(newVal){
      const filterFolder = this.folders.filter(folder => folder.folderId === newVal);
      this.folderName = filterFolder[0].name;
    }
  },
  methods: {
    closeSidebar() {
      this.$emit("close");
    },

    openPermisssions() {
      this.$emit("open-permisssions");
    },

    dragOver(e) {
      e.preventDefault();
      e.target.style.background = "#eee";
    }, 

    dragLeave(e) {
      e.target.style.background = "#FFFFFF";
    },

    async handleDropFiles(e) {
      const dropRef = this.$refs.dropContainer;
      const dropFirstChild = this.$refs.dropFirstChild;
      const dropFirstInner = this.$refs.dropFirstInner;
      const btnDrop = this.$refs.btnDrop;
      dropRef.style.background = "#FFFFFF";
      dropFirstChild.style.background = "#FFFFFF";
      dropFirstInner.style.background = "#FFFFFF";
      btnDrop.style.background = "#F15A29";
      const items = e.dataTransfer.items;
      const files = await this.getFolderFilesFromItems(items);

      if (files.length > 0) {
        this.filesProcessor(Array.from(files))
      } else {
        return this.$toasted.error(
          "Direct folder drop from external sites is restricted by browser security.",
          {
              duration: 6000,
          }
        );
      }
    },

    async getFolderFilesFromItems(items) {
      const folderFiles = [];
      for (const item of items) {
        if (item.kind === 'file') {
          const entry = item.webkitGetAsEntry();
          if (entry.isDirectory) {
            folderFiles.push(...await this.readEntryDirectory(entry));
          } else {
            folderFiles.push(item.getAsFile());
          }
        }
      }
      return folderFiles;
    },

    readEntryDirectory(directory) {
      return new Promise((resolve, reject) => {
        const reader = directory.createReader();
        const files = [];
        const readEntries = () => {
          reader.readEntries(async (entries) => {
            if (entries.length === 0) {
              resolve(files);
            } else {
              for (const entry of entries) {
                if (entry.isFile) {
                  files.push(await this.getFile(entry));
                } else if (entry.isDirectory) {
                  files.push(...await this.readEntryDirectory(entry));
                }
              }
              readEntries();
            }
          }, reject);
        };
        readEntries();
      });
    },

    getFile(entry) {
      return new Promise((resolve, reject) => {
        entry.file(resolve, reject);
      });
    },

    triggerInputFile(e) {
      this.$refs.fileInput.click();
    },

    handleInputFiles(e) {
      const selectedFiles = Array.from(e.target.files);
      this.filesProcessor(selectedFiles);
    },

    filesProcessor(fileList) {
      const allowedFileType = [ "pdf", "doc", "xlsx", "csv", "ppt", "png", "jpg", "jpeg", "sheet" ]
      this.uploadFile = fileList

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        const fileExtension = file.type.split('/').pop().toLowerCase();
        let type = fileExtension === "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? "xlsx" : fileExtension;
        if (!allowedFileType.includes(type)) {
          this.$toasted.error(`File type not supported: ${file.name}`, { duration: 6000 });
          break;
        }
        const fileObj = {
          file,
          status: 'pending',
          imageSizeMB: 0,
        };
        this.uploadImage(fileObj);
      }
    },

    async uploadImage(fileObj) {
      if (!fileObj) return;

      this.uploading = true;
      const formData = new FormData();
      formData.append('file', fileObj.file);
      const url = process.env.VUE_APP_IMAGE_UPLOAD;

      try{
          const { data } = await axios.post(url, formData, {
              onUploadProgress: (progressEvent) => {
                this.imageSizeMB = progressEvent.loaded / 1024 / 1024; // convert to MB
              }
          });
          let name = fileObj.file.name;
          this.fileName = name.substr(0, name.lastIndexOf(".")).replace(/[\d\(\)-]+/g, '');
          const fileExtension = fileObj.file.type.split('/').pop().toLowerCase();
          let type = fileExtension === "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? "xlsx" : fileExtension;
          this.fileType = type;
          this.allFiles.push({ 
              fileUrl: data.fileUrl, 
              fileSize: this.imageSizeMB,
              mimeType: this.fileType,
              fileName: this.fileName
            });
          this.uploading = false;
          this.isUploaded = true;
      }catch(error){
          this.uploading = true;
          this.networkError = true;
      };
    },

    cancelUpload() {
      this.reset();
      this.uploadFile = null;
    },

    deleteUploadedFile(index) {
      if (this.allFiles.length > 1) {
          this.allFiles.splice(index, 1);
      } else {
          this.allFiles.splice(index, 1); 
          this.reset();
          this.uploadFile = null;
          this.fileName = '';
          this.fileType = '';
          this.imageSizeMB = 0;
          this.isUploaded = false;
      }
    },

    retryUpload() {
      if (this.uploadFile) {
        this.uploading = false;
        this.networkError = false;
        this.filesProcessor(this.uploadFile);
      }
    },

    reset() {
      this.uploading = false;
      this.networkError = false;
    },

    async submit() {
      const userId = this.$AuthUser.id;
      const orgId =  this.$orgId;
      const payload = { 
        files: this.allFiles,
        folderId: this.selectedOption.id, 
        userId, 
        orgId
      };
      this.isLoading = true;

      try{
        await this.$_uploadAFile(payload);
        this.isLoading = false;
        this.$emit("close");
        this.$emit('refresh');

        return this.$toasted.success(`File uploaded successfully.`, {duration: 6000});
      }catch(err){
        this.isLoading = false;
        return this.$toasted.error(
                `Error trying to save file upload changes please check your internet conncetion`,
                {
                  duration: 6000,
                }
        );
      }
    },

    setOptionsBox() {
      if(this.folders.length > 0){
        this.selected = this.folders[0].folderId;
        this.folderName = this.folders[0].name;
        for (let i = 0; i < this.folders.length; i++) {
          const option = { id: this.folders[i].folderId, name: this.folders[i].name };
          this.optionsBox.push(option);
        }
      }
    }
  },
  mounted() {
    this.setOptionsBox()
  }
};
</script>

<style scoped>
.storage{
  width: 80px !important;
  height: 80px !important;
}

.icon{
  width: 100% !important;
  height: 100% !important;
}

.type{
  width: 26.15px;
  height: 13.12px;
  background: #219653;
  position: absolute;
  left: 2px;
  bottom: 6px;
  border-radius: 2px;
  text-align: center;
}

.type p{
  font-size: 10px;
}

.line{
  border: 1px solid #878E99;
  width: 85%;
  height: 0; 
  margin: 14px 0px;
}
</style>
